<template>
  <div class="col-lg-3 col-md-4 col-sm-6 col-12 mt-4">
    <div class="large-card text-center">
      <div class="content-overlay"></div>
      <div class="img_wrapper" :class="{ selected: info.selected }">
        <Media :info="info.data" />
      </div>
      <div class="content-wrapper">
        <h3 class="title">{{ info.data.name }}</h3>
        <span class="asset-price text-success">Staking Reward {{ power }}</span>
        <button
          v-show="power"
          class="btn btn-block w-100 text-center my-2"
          @click.prevent="addToBag(info)"
        >
          Stake
        </button>
        <button
          v-show="power == 0"
          class="btn btn-block w-100 text-center my-2"
        >
          Non Stakeable
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services";
import { useToast } from "vue-toastification";
import Media from "@/components/Media";
const toast = useToast();
export default {
  name: "AssetCard",
  props: ["rec", "row", "power"],
  components: {
    Media,
  },
  data() {
    return {
      isImageLoaded: false,
      showReadyButton: false,
    };
  },
  computed: {
    info: function () {
      return this.rec;
    },
  },
  methods: {
    getImgUrl(pet, isVid) {
      return ApiService.getImgUrl(pet, isVid);
    },
    getVidUrl(pet) {
      return ApiService.getImgUrl(pet, true);
    },
    loaded() {
      this.isImageLoaded = true;
    },
    async addToBag(rec) {
      const data = {
        from: localStorage.getItem("wax_user"),
        to: process.env.VUE_APP_CONTRACT,
        asset_ids: [rec.asset_id],
        memo: ``,
      };
      await ApiService.setStake(
        data,
        (res) => {
          if (res.processed) {
            toast.success("Stake has been made.");
            setTimeout(() => {
              this.$emit("refresh");
            }, 2000);
          }
        },
        (error) => {
          this.error = ApiService.errorFormat(error);
          toast.error(this.error);
        }
      );
    },
  },
};
</script>

<style scope>
a {
  text-decoration: none !important;
}
.card {
  box-shadow: -1px 8px 19px 4px rgba(0, 0, 0, 0.3);
}
.select-btn {
  border: 1px solid gray;
  width: 100px;
  text-align: center;
  height: 36px;
  margin: 0 auto;
  font-size: 20px;
  color: #fff;
  border-radius: 10px;
  line-height: 30px;
}
.large-card {
  display: inline-block;
  text-align: left;
  position: relative;
  background: var(--primaryColor);
  cursor: pointer;
  margin: 10px 15px;
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
  width: 200px;
  height: auto;
  border: 2px solid #fff !important;
}
.large-card .img_wrapper {
  height: 205px;
}
.large-card .img-position {
  display: block;
  width: 100%;
  height: 100%;
}
.large-card .single-pic {
  padding: 15px 15px 0;
}
.large-card .img_wrapper .img-prev {
  position: relative;
  width: 100%;
  height: 100%;
}
.large-card .img_wrapper .img-prev img {
  position: absolute;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.large-card .content-wrapper {
  padding: 0px 10px;
}
.large-card .content-wrapper .title {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
  padding-top: 2px;
  margin-bottom: 0;
  height: 40px;
}
.large-card .content-wrapper .asset-price,
.large-card .content-wrapper button {
  color: #fff;
  font-size: 14px;
}
.btn-block:hover {
  color: black !important;
  font-weight: bold;
}
</style>