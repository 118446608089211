import axios from 'axios'
import * as waxjs from "@waxio/waxjs/dist";
import Link from 'anchor-link'
import {
    useToast
} from 'vue-toastification'
const toast = useToast()
import {
    isWebUri
} from 'valid-url';
import moment from 'moment';
import BrowserTransport from 'anchor-link-browser-transport'
var keys = null
var laccount = null
if (localStorage.getItem("ual-wax:autologin") != null) {
    let ualWax = JSON.parse(localStorage.getItem("ual-wax:autologin"))
    keys = ualWax ? ualWax.pubKeys : null
}
if (localStorage.getItem("wax_user") != null) {
    laccount = localStorage.getItem("wax_user")
}
const waxOptions = {
    rpcEndpoint: process.env.VUE_APP_CHAIN_URL,
    userAccount: localStorage.getItem("wax_user") || null,
    pubKeys: keys,
    tryAutoLogin: false
}
var wax = new waxjs.WaxJS(waxOptions);

const getImgUrl = (hash, isVideo) => {
    if (isWebUri(hash)) {
        return hash;
    }
    let url = 'https://ipfs.io/ipfs/' + hash;
    if (isVideo) {
        return url
    }
    return '//images.weserv.nl/?url=' + url + "&w=300&h=300";
}
const callApi = async(params, callback) => {
    axios
        .get(`${process.env.VUE_APP_API_ASSET_URL}?owner=${localStorage.getItem('wax_user')}&page=1&limit=100${params}`)
        .then(() => callback)
}
const link = new Link({
    transport: new BrowserTransport({
        requestStatus: false
    }),
    chains: [{
        chainId: process.env.VUE_APP_CHAIN_ID,
        nodeUrl: process.env.VUE_APP_CHAIN_FULL_URL,
    }],
})
const setStake = async(dataParams, onSuccess, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    const authenticator = localStorage.getItem("ual-session-authenticator");
    try {
        const actionData = {
            account: "atomicassets",
            name: 'transfer',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        if (authenticator.toLowerCase() == "anchor") {
            return await doSign(actionData, onSuccess, onErrorCallback)
        } else {
            return await signWithWax({
                actions: [actionData]
            }, onSuccess, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
const resetOrClaim = async(action, asset_id, onSuccess) => {
    let authenticator = localStorage.getItem("ual-session-authenticator");
    let userAccount = localStorage.getItem("wax_user")
    const actionData = {
        account: process.env.VUE_APP_CONTRACT,
        name: action,
        authorization: [{
            actor: userAccount,
            permission: 'active',
        }],
        data: {
            owner: userAccount,
            asset_id: asset_id,
        },
    };
    if (authenticator.toLowerCase() == "anchor") {
        await doSign(actionData, (onSuccess), ((error) => {
            toast.error(errorFormat(error));
            return error;
        }));
    } else {
        try {
            await signWithWax({
                actions: [actionData]
            }, (onSuccess), ((error) => {
                toast.error(errorFormat(error));
                return error;
            }));
        } catch (error) {
            toast.error(errorFormat(error));
            return error;
        }
    }
}
const getById = async(id, callback, onError) => {
    return await axios
        .get(`${process.env.VUE_APP_API_ASSET_URL}/${id}`)
        .then(res => res.data.data)
        .then(callback)
        .catch(onError);
}
const getTable = async(table, lowerBound = null, callBack, onError) => {
    const activeUser = localStorage.getItem("wax_user");
    return await wax.rpc.get_table_rows({
        scope: activeUser,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 100,
        table: table,
        lower_bound: lowerBound
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res;
        }
        return [];
    }).then((res) => callBack(res)).catch(onError)
}
const tablePagination = async(table, key, callBack, onError) => {
    const activeUser = localStorage.getItem("wax_user");
    return await wax.rpc.get_table_rows({
        scope: activeUser,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 100,
        table: table,
        lower_bound: key
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res;
        }
        return [];
    }).then((res) => callBack(res)).catch(onError)
}
const getTable2 = async(table, lowerBound = null, callBack, onError) => {
    return await wax.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        limit: 100,
        table: table,
        lower_bound: lowerBound
    }).then(async(res) => {
        if (res.rows.length > 0) {
            return res;
        }
        return [];
    }).then((res) => callBack(res)).catch(onError)
}
const getStake = async(key, onSuccess) => {
    return await tablePagination("userstakes", key, async(result) => {
            if (result.rows !== undefined) {
                await result.rows.map(async(item, i) => {
                    await getById(item.asset_id, (asset) => {
                        result.rows[i]["asset"] = asset;
                        result.rows[i]["expiredDate"] = moment.utc(item.stake_time).format('YYYY-MM-DDTHH:mm:ss.SSSS');
                        result.rows[i]["currentUtc"] = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSS');
                    });
                });

                return onSuccess(result);
            } else {
                return onSuccess([]);
            }
        },
        (error) => {
            toast.error(errorFormat(error));
            return error;
        })
}
const doSign = async(action, onSuccessCallback, onErrorCallback) => {
    await link.restoreSession(process.env.VUE_APP_NAME).then((session) => {
        session.transact({
            actions: (action instanceof Array) ? action : [action]
        }, {
            blocksBehind: 1,
            expireSeconds: 120,
        }).then(onSuccessCallback).catch(onErrorCallback)
    }).catch(async(error) => {
        const identity = await link.login(process.env.VUE_APP_NAME)
        const {
            session
        } = identity
        session.transact({
            actions: (action instanceof Array) ? action : [action]
        }, {
            blocksBehind: 1,
            expireSeconds: 120,
        }).then(onSuccessCallback).catch(onErrorCallback)
        return error;
    })
}
const signWithWax = async(action, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    let isAutoLoginAvailable = await wax.isAutoLoginAvailable();
    if (!isAutoLoginAvailable) {
        await wax.login();
    }
    try {
        await wax.api.transact(action, {
            blocksBehind: 3,
            expireSeconds: 120,
            broadcast: true,
            sign: true,
        }).then(onSuccessCallback).catch(onErrorCallback)
    } catch (error) {
        return onErrorCallback(error)
    }
}
const isLogin = () => {
    // check local store and also expiry of session
    if (localStorage.getItem('wax_user')) {
        var sessionDate = localStorage.getItem("ual-session-expiration");
        var d1 = new Date();
        var d2 = new Date(sessionDate);
        return moment(d2).isAfter(moment(d1))
    } else {
        return false
    }
}
const transaction = async(dataParams, onSuccessCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: dataParams.contract,
            name: dataParams.action,
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams.data,
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await doSign(actionData, onSuccessCallback, (error) => {
                error = errorFormat(error);
                toast.error(error);
            })
        } else {
            return await signWithWax({
                actions: [actionData]
            }, onSuccessCallback, (error) => {
                error = errorFormat(error);
                toast.error(error);
            })
        }
    } catch (err) {
        let error = errorFormat(err);
        toast.error(error);
    }
}
const errorFormat = (error) => {
    console.log(error.message, typeof error);
    if (typeof error == "string") {
        console.log(error);
        return "Something went wrong."
    }
    if (!error.error && error.message) {
        return error.message
    }
    if (error.error.what) {
        if (error.error.details) {
            return error.error.details[0].message
        }
        return error.error.what;
    } else {
        return "Transaction failed,try again."
    }
}
const actionCall = async(callback) => {
    return await axios
        .get(`https://wax.pink.gg/v2/history/get_actions?account=${localStorage.getItem('wax_user')}&filter=${process.env.VUE_APP_CONTRACT}%3Adailyclaim&skip=0&limit=1&sort=desc`)
        .then(res => res.data.actions)
        .then(callback)
}
const getBalance = async(callBack) => {
    return await axios
        .post(`${process.env.VUE_APP_CHAIN_API}/get_currency_balance`, {
            account: localStorage.getItem("wax_user"),
            code: "luminascoins",
            symbol: "NOLA",
        }).then(callBack)
}
const successMsg = (msg) => {
    if (msg) {
        toast.success(msg);
    } else {
        toast.success("Transaction successfull");
    }
}
const info = (msg) => {
    if (msg) {
        toast.info(msg);
    } else {
        toast.info("Transaction successfull");
    }
}
const market = async(callBack, lowerBound = null) => {
    return await wax.rpc.get_table_rows({
        scope: process.env.VUE_APP_CONTRACT,
        code: process.env.VUE_APP_CONTRACT,
        index_position: 1,
        json: true,
        lower_bound: lowerBound,
        limit: 10,
        table: process.env.VUE_APP_TBL_MARKET
    }).then(async(res) => {
        const requests = res.rows.map(async(val, index) => {
            let nfts = await getByTempleteId(val.asset_t, (res) => res.data.data)
            res.rows[index].asset = nfts
            return val
        });
        return Promise.all(requests).then(() => {
            return res
        });
    }).then((res) => callBack(res))
}
const getByTempleteId = async(tmpId, callback) => {
    return axios
        .get(`${process.env.VUE_APP_API_TEMPLATE_URL}/ancientoasis/${tmpId}`)
        .then((res) => callback(res))
}
const buy = async(dataParams, onSuccessCallback, onErrorCallback) => {
    laccount = localStorage.getItem("wax_user");
    try {
        const actionData = {
            account: "luminascoins",
            name: 'transfer',
            authorization: [{
                actor: laccount,
                permission: 'active',
            }],
            data: dataParams,
        }
        if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await doSign(actionData, onSuccessCallback, onErrorCallback)
        } else {
            return await signWithWax({
                actions: [actionData]
            }, onSuccessCallback, onErrorCallback)
        }
    } catch (err) {
        return onErrorCallback(err)
    }
}
export default {
    successMsg,
    errorFormat,
    getBalance,
    actionCall,
    doSign,
    isLogin,
    resetOrClaim,
    transaction,
    callApi,
    getTable,
    getStake,
    setStake,
    market,
    info,
    getTable2,
    signWithWax,
    getImgUrl,
    buy
}