<template>
  <div class="col-12 col-lg-3 mb-4 textCenter">
    <div class="large-card text-center shop_card d-block m-auto">
      <div class="content-overlay"></div>
      <div class="img_wrapper">
        <a href="JavaScript:void(0)" class="img-position single-pic p-0">
          <div class="img-prev" v-if="info.asset">
            <Media :info="info.asset.data" />
          </div>
        </a>
      </div>
      <div class="content-wrapper pb-3">
        <h1 class="title">{{info.asset.name}}</h1>
        <h3 class="text-success">{{ parseInt(winningToken) }} NOLA</h3>
        <h6 class="text-warning">Reward Every 6 hours</h6>
        <button
          class="btn btn-block xs-btn text-white"
          @click.prevent="reset(info)"
          v-if="!stakeOver"
        >
          Release
        </button>
        <button
          class="btn btn-block xs-btn text-white"
          @click.prevent="claim(info)"
          v-if="stakeOver"
        >
          Claim
        </button>
        <div class="col-md-12" v-if="!stakeOver">
          <div class="timeBlock">
            <h5 class="text-center">Next Drip in</h5>
            <h2>{{ counter }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services";
import moment from "moment";
import { useToast } from "vue-toastification";
import Media from "@/components/Media";
const toast = useToast();
export default {
  name: "StakeCard",
  props: ["rec", "row"],
  data() {
    return {
      isImageLoaded: false,
      showReadyButton: false,
      counter: "0",
      endTime: "",
      winningToken: 0,
      hoursLeft: 1,
      stakeOver: false,
    };
  },
  components: {
    Media
  },
  computed: {
    info: function () {
      return this.rec;
    },
  },
  async mounted() {
    this.winningToken = parseFloat(this.info.reward.replace(/NOLA/g, ""));
    this.endTime = moment(this.info.last_claimed)
      .add(6, "hours")
      .format("YYYY-MM-DDTHH:mm:ss.SSSS");
    this.hoursLeft = Math.ceil(
      moment
        .duration(moment(this.endTime).diff(moment(this.info.currentUtc)))
        .asHours()
    );
    // this.duration = res.duration;
    this.countDown = setInterval(() => {
      let currentUtc = moment
        .utc(new Date())
        .format("YYYY-MM-DDTHH:mm:ss.SSSS");
      if (this.hoursLeft < 1) {
        this.counter = moment
          .utc(moment(this.endTime).diff(currentUtc))
          .format("mm:ss");
      } else {
        this.hour = moment
          .duration(moment(this.endTime).diff(currentUtc))
          .asHours();
        this.counter =
          Math.trunc(this.hour) +
          ":" +
          moment
            .utc(moment(this.endTime).diff(moment(currentUtc)))
            .format("mm:ss");
      }
      if (
        this.counter == "00:00" ||
        this.counter.indexOf("-") !== -1 ||
        !Object.is(Math.abs(this.hoursLeft), +this.hoursLeft)
      ) {
        this.counter = 0;
        this.stakeOver = true;
        clearInterval(this.countDown);
      }
    }, 1000);
    if (this.stakeOver) {
      clearInterval(this.countDown);
    }
  },
  methods: {
    getImgUrl(pet, isVid) {
      return ApiService.getImgUrl(pet, isVid);
    },
    loaded() {
      this.isImageLoaded = true;
    },
    async claim() {
      await ApiService.resetOrClaim(
        "claim",
        this.info.asset_id,
        (res) => {
          if (res.processed) {
            toast.success("Reward claimed");
            setTimeout(() => {
              this.$store.dispatch("getBalance");
              this.$emit("refresh");
            }, 2000);
          }
        },
        (error) => {
          this.error = ApiService.errorFormat(error);
          toast.error(this.error);
        }
      );
    },
    async reset() {
      await ApiService.resetOrClaim(
        "unstake",
        this.info.asset_id,
        (res) => {
          if (res.processed) {
            toast.success("Stake Canceled");
            setTimeout(() => {
              this.$router.push("dashboard");
            }, 2000);
          }
        },
        (error) => {
          this.error = ApiService.errorFormat(error);
          toast.error(this.error);
        }
      );
    },
  },
};
</script>

<style>
.content {
  height: 70%;
}
.select-card {
  width: 250px;
  position: absolute;
}
.rowBlock {
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: -1px 8px 19px 4px rgb(222 215 215 / 30%);
  padding: 10px;
}

.imgBlock {
  height: 100px;
}
.rowBlock .imgBlock img {
  height: 100%;
}
.txtBlock h1,
h5,
.timeBlock h2 {
  color: #fff;
  text-align: center;
}
.txtBlock h1 {
  font-size: 30px;
}
.btn {
  width: 150px;
  letter-spacing: 1px;
  font-weight: bold;
  transition: all 0.4s;
  border: 2px solid #fff !important;
}
.btn:hover {
  background: white;
  color: black !important;
  transform: translateY(-4px);
  box-shadow: 10px 15px 20px rgba(0, 0, 0, 0.7);
}
.btn-cancel {
  border-radius: 4px;
  color: #fff !important;
}
</style>