<template>
  <section class="shop-wrapper h-100">
    <div class="container">
      <h1 class="head-title">Shop</h1>
      <div class="row justify-content-center">
        <button
          class="btn btn-filter bg_secondary text-capitalize"
          @click="openFiltersList = !openFiltersList"
        >
          <i class="fa fa-filter" aria-hidden="true"></i>
          filters
        </button>
        <div class="col-lg-3 col-12">
          <section class="filter-wrapper">
            <h4 class="title text-white total_balance">
              Your balance : <strong>{{ balance }}</strong>
            </h4>

            <div class="hideList" :class="openFiltersList ? 'showList' : ''">

              <div class="list-wrapper mt-4">
                <label for="#" class="filter-title ms-2 my-2">Schema Filter</label>
                <ul class="schema-list">
                  <li><a href="#" @click.prevent="filterBySchema('tokens')">Tokens</a></li>
                  <li><a href="#" @click.prevent="filterBySchema('promo')">Promo</a></li>
                  <li><a href="#" @click.prevent="filterBySchema('keys')">Keys</a></li>
                  <li><a href="#" @click.prevent="filterBySchema('limited')">Limited</a></li>
                  <li><a href="#" @click.prevent="filterBySchema('sketch.pack')">Sketch Pack</a></li>
                  <li><a href="#" @click.prevent="filterBySchema('sketch.drop')">Sketch Drop</a></li>
                </ul>
              </div>
            </div>
          </section>
        </div>
        <div class="col-lg-9 col-12">
          <div class="row">
            <loading
              v-model:active="isLoading"
            />
            <div
              class="col-md-3 col-lg-3 col-md-6 col-12"
              v-for="(item, i) in info"
              :key="i"
            >
              <div class="large-card text-center shop_card" v-if="item.asset">
                <div class="content-overlay"></div>
                <div class="img_wrapper">
                  <Media :info="item.asset.immutable_data" />
                </div>
                <div class="content-wrapper">
                  <h3 class="title">{{ item.asset.immutable_data.name }}</h3>
                  <span class="asset-price text-success">
                    Price :{{ Number(parseFloat(item.price)).toFixed(0) }} NOLA</span
                  >
                  <p class="asset-price text-warning">
                    Sales :{{ item.total_sale }}/{{item.supply}}</p
                  >

                  <button
                    class="btn btn-block w-100 text-center my-2"
                    @click="buyTransact(item.asset_t, item.price)"
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="more">
            <nav aria-label="Page navigation" class="d-flex justify-content-center my-3">
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#" @click="dataReload(null)">Refresh</a></li>
                <li class="page-item"><a class="page-link" href="#" @click="dataReload(nextKey)">Next 10</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from "vue-loading-overlay";
import ApiService from "@/services";
import { mapGetters } from "vuex";
import { useToast } from "vue-toastification";
import Media from "../components/Media.vue";
const toast = useToast();
export default {
  components: {
    Loading,
    Media,
  },
  computed: {
    ...mapGetters(["balance"]),

    price(price) {
      return Number(parseFloat(price)).toFixed(2);
    },
  },

  data() {
    return {
      selectedInfo: true,
      openFiltersList: false,
      info: null,
      apiRes: null,
      isLoading: false,
      more: false,
      nextKey: null,
    };
  },
  methods: {
    async buyTransact(templateId, price) {
      const memo = `${templateId}:1`;
      if (templateId && price) {
        await ApiService.buy(
          {
            from: localStorage.getItem("wax_user"),
            to: process.env.VUE_APP_CONTRACT,
            quantity: price,
            memo: memo,
          },
          (res) => {
            if (res.processed) {
              toast.success("Transaction processing.");
            }
          },
          (error) => {
            error = ApiService.errorFormat(error);
            toast.error(error);
          }
        );
      } else {
        this.error = "Please specify quantity";
        toast.error(this.error);
      }
    },
    getImgUrl(hash) {
      return ApiService.getImgUrl(hash, false);
    },
    filterBySchema(schema){
      this.info = this.apiRes.filter(rec=>rec.schema==schema);
      if(this.info.length==0){
        ApiService.info("No record found.");
      }
    },
    dataReload(key){
      ApiService.market((res) => {
        this.info = res.rows;
        this.more = res.more;
        this.nextKey = res.next_key;
        this.apiRes = res;
        this.isLoading = false;
        this.$store.commit("addMarket", this.info);
      },key);
    }
  },
  mounted() {
    ApiService.getBalance((res) =>
      this.$store.commit("addBalance", res.data[0])
    );

    this.isLoading = true;
    this.dataReload(null);
  },
};
</script>

<style scoped>
/*.large-card {
  margin-top: 0 !important;
}*/

/* .shop-wrapper {
  margin-top: 10%;
} */
.filter-wrapper .showList {
  display: block;
}
.btn-filter {
  float: right;
  color: #fff;
  position: relative;
  top: 36px;
  display: none;
  width: 154px;
  margin-left: auto;
}
</style>