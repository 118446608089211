<template>
  <div class="login">
    <div id="ual-div"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import LoginService from "../services/LoginService";
export default {
  name: "Login",
  data() {
    return {
      ual: null,
      showPopup: false,
    };
  },
  inheritAttrs: false,
  mounted() {
    localStorage.removeItem("ual-session-authenticator");
    localStorage.removeItem("ual-session-expiration");
    localStorage.removeItem("autoLogin");
    localStorage.removeItem("loglevel:webpack-dev-server");
    localStorage.removeItem("ual-wax:autologin");
    localStorage.removeItem("wax_user");
    localStorage.clear();
    this.handle_login();
    if (this.ual) {
      this.ual.init();
      var [buttonUAL] = document.body.getElementsByClassName("ual-button-gen");
      buttonUAL.click();
      let elements = document.body.getElementsByClassName("ual-button-gen");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    } else {
      localStorage.clear();
      const loginService = new LoginService();
      this.ual = loginService.handle_login();
    }
  },
  methods: {
    handle_login: function () {
      if (this.ual) {
        this.ual.init();
        var [buttonUAL] =
          document.body.getElementsByClassName("ual-button-gen");
        buttonUAL.click();
        let elements = document.body.getElementsByClassName("ual-button-gen");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      } else {
        localStorage.clear();
        const loginService = new LoginService();
        this.ual = loginService.handle_login();
      }
    },
  },
};
</script>

<style>
.ual-button-gen {
  display: none !important;
}
#ual-authenticators-list {
  display: flex !important;
}
.ual-auth-button {
  display: flex !important;
  width: 185px !important;
  margin-right: 10px !important;
  height: 53px;
}

.ual-auth-text {
  display: inline-block !important;
  padding: 20px 4px 17px 10px !important;
  font-size: 10px !important;
  width: 137px !important;
}
#ual-modal-selection-content {
  font-family: "Source Sans Pro", sans-serif;
  background-color: transparent !important;
  border: 1px solid transparent !important;
  margin: 10% auto;
  padding: 30px;
  border-radius: 6px;
  width: 428px !important;
}
.fa-chevron-right {
  font-size: 16px !important;
  float: right;
  margin: 12px 6px;
}

.ual-modal-content-description {
  color: #fff !important;
  width: 246px !important;
  font-size: 1.2em !important;
  margin: 0.7em auto;
}
.ual-modal-close {
  display: none;
}
#ual-modal {
  top: 78px !important;
}
</style>