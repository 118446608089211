<template>
  <div class="home">
    <section class="card_section">
      <div class="container">
        <loading
          v-model:active="isLoading"
          :can-cancel="true"
          :is-full-page="true"
        />
        <div
          class="row justify-content-center align-items-center"
          v-if="!isLoading"
        >
          <h4 class="top-heading">Oasis Rewards</h4>
          <div class="w-100 text-center">
            <button
                class="btn btn-block xs-btn text-white"
                @click.prevent="claimAll()"
                v-show="maxClaimCount>0"
              >
                Claim All
            </button>
          </div>
          <div class="row mt-3">
          <StakeCard
            v-for="(item, index) in stakes"
            :key="index"
            :row="index"
            :rec="item"
            v-on:refresh="dataReload"
          />
          </div>
          <div class="col-12" v-if="more">
            <nav aria-label="Page navigation" class="d-flex justify-content-center my-3">
              <ul class="pagination">
                <li class="page-item"><a class="page-link" href="#" @click="dataReload(null)">Refresh</a></li>
                <li class="page-item"><a class="page-link" href="#" @click="dataReload(nextKey)">Next 10</a></li>
              </ul>
            </nav>
          </div>
          <h1 v-if="stakes == null || stakes.length==0" class="text-center text-white">
            No Reward found.
          </h1>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import ApiService from "@/services";
import { mapState } from "vuex";
import StakeCard from "@/components/StakeCard";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import moment from "moment";
export default {
  name: "staked",
  components: {
    StakeCard,
    Loading,
  },
  data() {
    return {
      countryStrokeColor: "#fa0909",
      legendBorderRadius: 1,
      showReadyButton: false,
      isLoading: true,
      actionData: [],
      authenticator: null,
      maxClaimCount: 0,
      more: false,
      nextKey:null
    };
  },
  computed: mapState(["stakes"]),
  async created() {
    await this.dataReload();
  },
  mounted() {
    if (!localStorage.getItem("wax_user")) {
      router.push("/login");
    }
    this.authenticator = localStorage.getItem("ual-session-authenticator");
    if(this.isLoading == false){
      console.log(this.actionData);
    }
    setTimeout(() => {
      console.log(this.actionData);
    }, 3000);
  },
  methods: {
    getImgUrl(pet, isVid) {
      return ApiService.getImgUrl(pet, isVid);
    },
    async dataReload(key) {
      this.isLoading = true;
      await ApiService.getStake(key,(result) => {
        const row = result.rows;
        this.more = result.more;
        this.nextKey = result.next_key;
        this.$store.commit("addStake", row);
        setTimeout(() => {
          for (let i = 0; i < this.stakes.length; i++) {
            const row = this.stakes[i];
            const lastClaim = row.last_claimed;
            const claimTimeLeft = moment(lastClaim).add(6, 'hours');
            const startTime = Math.ceil(moment.duration(moment(claimTimeLeft).diff(moment(moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSS')))).asHours());
            if (this.maxClaimCount==0){
              if(!Object.is(Math.abs(startTime), +startTime) || startTime==0){
                this.maxClaimCount += 1;
              }
            }
          }
          this.isLoading = false;
        }, 2500);
      });
    },
    async claimAll(){
      if (this.maxClaimCount==0)return;
      this.actionData.push({
        account: process.env.VUE_APP_CONTRACT,
        name: 'claim.all',
        authorization: [{
          actor: localStorage.getItem("wax_user"),
          permission: 'active',
        }],
        data: {
          stake_owner: localStorage.getItem("wax_user"),
        },
      });
       if (localStorage.getItem("ual-session-authenticator") != "Wax") {
            return await ApiService.doSign(this.actionData, (res)=>{
              if(res.processed.id){
                ApiService.successMsg("Transaction successfull, refreshing.");
                this.actionData=[];
                setTimeout(() => {
                  this.dataReload();
                }, 3000);
              }
            }, ApiService.defaultErrorCallback)
        } else {
            return await ApiService.signWithWax({ actions: this.actionData }, (res)=>{
              if(res.processed.id){
                ApiService.successMsg("Transaction successfull, refreshing.")
                this.actionData=[];
                setTimeout(() => {
                  this.dataReload();
                }, 3000);
              }
            }, ApiService.defaultErrorCallback)
        }
    }
  },
};
</script>
<style scoped>
.content {
  height: 70%;
}
.nft {
  width: 120px;
}
.select-card {
  width: 250px;
  position: absolute;
}
.text-white {
  color: #fff;
}
.xs-btn{
  width: 150px;
  margin-bottom: 20px;
}
</style>