<template>
  <ul class="navbar-nav mr-auto" :class="`${isOpen ? 'show' : ''}`">
    <li class="nav-item mt-2">
      NOLA: <strong>{{ balance }}</strong>
    </li>
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle"
        @click="optionDropdown(isOpen)"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {{ logUser }} <i class="fa fa-user-circle-o" aria-hidden="true"></i>
      </a>
      <div
        class="dropdown-menu"
        :class="`${isOpen ? 'show' : ''}`"
        aria-labelledby="navbarDropdown"
      >
        <a class="dropdown-item" href="/login">logout</a>
      </div>
    </li>
  </ul>
</template>

<script>
// import router from "../router";

export default {
  name: "dropdown",
  data() {
    return {
      isOpen: false,
      showItem: "",
    };
  },
  props: {
    logUser: {
      required: true,
    },
    balance: {
      required: true,
    },
  },
  methods: {
    optionDropdown(isOpen) {
      this.isOpen = !isOpen;
    },
    logout: function () {
      this.$router.replace("/login");
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  min-width: 100% !important;
  background: black !important;
  border: 1px solid #fff !important;
}

.dropdown-menu a {
  color: #fff !important;
}
.dropdown-menu a:hover {
  background: transparent !important;
}
.nav-item {
  font-weight: bold;
  /* font-size: 20px; */
}
</style>