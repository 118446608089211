<template>
  <div class="home">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="true"
    />
    <section class="card_section pb-5">
      <div class="container text-center">
        <h4 class="top-heading mb-3" v-if="info != null">Your inventory</h4>
        <select
          v-if="info != null"
          class="form-select mx-auto input-select"
          aria-label="Default select example"
          @change="filter($event)"
        >
          <option selected>Schema</option>
          <option value="promo">Promo</option>
          <option value="tokens">Tokens</option>
          <option value="promo">Promo</option>
          <option value="keys">Keys</option>
          <option value="limited">Limited</option>
          <option value="sketch.pack">Sketch Pack</option>
          <option value="sketch.drop">Sketch Drop</option>
        </select>

        <div class="row justify-content-center align-item-center" v-if="!isLoading">
          <AssetCard
            v-for="(item, index) in info"
            :key="index"
            :row="index"
            :power="getPower(item.template.template_id)"
            v-on:refresh="dataReload"
            :rec="item"
          />
          <nav aria-label="Page navigation" class="d-flex justify-content-center my-3" v-if="info.length==12">
            <ul class="pagination">
              <li class="page-item"><a class="page-link" href="#" @click.prevent="init('',1)">Refresh</a></li>
              <li class="page-item" v-for="index in page" :key="index"><a class="page-link" href="#" @click.prevent="init('',index)">{{index}}</a></li>
              <li class="page-item"><a class="page-link" href="#" @click.prevent="init('',page+1)">Next 12</a></li>
            </ul>
          </nav>
          <h1 v-if="!info" class="text-white text-center">No record found.</h1>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import router from "@/router";
import { mapGetters } from "vuex";
import AssetCard from "@/components/AssetCard";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  name: "Home",
  components: {
    AssetCard,
    Loading,
  },
  computed: {
    ...mapGetters(["stakePower"]),
  },
  data() {
    return {
      userFilter: null,
      authenticator: null,
      showReadyButton: false,
      info: null,
      apiRes: null,
      collections: null,
      searchTerm: "All Weapons",
      isLoading: true,
      fullPage: true,
      status: false,
      assetId: "",
      page: 1,
      row: {},
    };
  },
  mounted() {
    this.$store.dispatch("getPower");
    if (!localStorage.getItem("wax_user")) {
      router.push("/login");
    }
    this.authenticator = localStorage.getItem("ual-session-authenticator");
    this.init("",1);
  },
  methods: {
    filter(event) {
      this.init(`&schema_name=${event.target.value}`);
    },
    nextScreen() {
      router.push("/ready");
    },
    async dataReload() {
      this.init("",1);
    },
    getPower(tempId) {
      if (this.stakePower) {
        const a = this.stakePower.filter((item) => item.asset_t == tempId);
        if (a[0]) {
          return a[0].reward;
        }
      }
      return 0;
    },
    init(param,page) {
      if(page==this.page && page!=1){return}
      this.page = page
      axios
        .get(
          `${process.env.VUE_APP_API_ASSET_URL}?owner=${localStorage.getItem(
            "wax_user"
          )}&collection_name=ancientoasis&page=${page}&limit=12${param}`
        )
        .then((response) => {
          let res = response["data"];
          if (res["success"]) {
            this.apiRes = res["data"];
            this.info = res["data"];
          } else {
            this.info = [];
          }
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped>
select:focus {
  outline: none;
  box-shadow: none;
}
.top-heading {
  text-transform: uppercase;
  font-weight: bold;
}
.input-select {
  border-radius: 10px;
  width: 200px;
  margin: auto;
  background: #000;
  border: 1px solid #fff;
  color: #fff;
}
</style>
