import {
    createStore
} from 'vuex'
import ApiService from "../services"
export default createStore({
    state: {
        stakes: null,
        stakePower: null,
        balance: 0,
        market: null
    },
    actions: {
        getPower: function ({
            commit
        }) {
            ApiService.getTable2("stakepower", null, async (row) => {
                if (row) {
                    commit("addStakePower", row.rows);
                }
            }, null)
        },
        getBalance: function ({
            commit
        }) {
            ApiService.getBalance((res) =>
                commit("addBalance", res.data[0])
            );
        },
    },
    mutations: {
        addStakePower: (state, payload) => {
            if (payload) {
                return state.stakePower = payload;
            }
        },
        addStake: (state, payload) => {
            if (payload) {
                return state.stakes = payload;
            }
        },
        addBalance(state, val) {
            if (val) {
                val = parseFloat(val.toString());
                state.balance = val
            }
        },
        addMarket(state, val) {
            state.market = val
        },
    },
    getters: {
        stakePower: state => state.stakePower,
        stakes: state => state.stakes,
        balance: state => {
            if (state.balance) {
                let numberVal = state.balance
                return new Intl.NumberFormat().format(numberVal)
            }
            return 0;
        },
    }
})