<template>
  <template v-if="'video' in info">
    <a href="JavaScript:void(0)" class="img-position single-pic">
      <div class="img-prev">
        <video autoPlay loop muted>
          <source :src="getVidUrl(info.video)" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </a>
  </template>
  <template v-else>
    <a href="JavaScript:void(0)" class="img-position single-pic">
      <div class="img-prev">
        <img v-show="isImageLoaded" :src="getImgUrl(info.img)" @load="loaded" />
      </div>
      <div class="img-prev" v-if="!isImageLoaded">
        <img :src="'/images/loader.gif'" @load="loaded" />
      </div>
    </a>
  </template>
</template>
<script>
import ApiService from "@/services";
export default {
  name: "Media",
  props: ["info"],
  data() {
    return {
      isImageLoaded: false,
      showReadyButton: false,
    };
  },
  methods: {
    getImgUrl(pet, isVid) {
      return ApiService.getImgUrl(pet, isVid);
    },
    getVidUrl(pet) {
      return ApiService.getImgUrl(pet, true);
    },
    loaded() {
      this.isImageLoaded = true;
    },
  },
};
</script>
<style scoped>
.img-position {
  display: block;
  width: 100%;
  height: 100%;
}
.single-pic {
  padding: 15px 15px 0;
}
.img-prev {
  position: relative;
  width: 100%;
  height: 100%;
}
.img-prev video {
  position: absolute;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>