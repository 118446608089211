<template>
  <nav class="navbar navbar-expand-lg">
    <div class="container-fluid">
      <a class="navbar-brand logo_img" href="https://ancientoasis.world">
        <img src="images/an-logo.png" alt="" class="img-fluid" />
      </a>
      <button
        class="navbar-toggler"
        @click="optionDropdown(isOpen)"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
        <span class="" role="button"
          ><i class="fa fa-bars" aria-hidden="true" style="color: #e6e6ff"></i
        ></span>
      </button>
      <div
        class="collapse navbar-collapse"
        :class="`${isOpen ? 'show' : ''}`"
        id="navbarText"
      >
        <ul class="navbar-nav me-auto mb-lg-0">
          <li class="nav-item">
            <router-link 
              class="nav-link hover-underline-animation" 
              to="/"
              @click="isOpen = false"
              >Collection</router-link
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link mx-lg-5 hover-underline-animation"
              to="/rewards"
              @click="isOpen = false"
              >Rewards</router-link
            >
          </li>
          <li class="nav-item">
            <router-link 
              class="nav-link hover-underline-animation" 
              to="/shop"
              @click="isOpen = false"
              >Shop</router-link
            >
          </li>
        </ul>
        <span class="navbar-text">
          <Dropdown :logUser="authentication.user_name" :balance="balance" />
        </span>
      </div>
    </div>
  </nav>
</template>
<script>
import Dropdown from "@/views/Dropdown";
import ApiService from "@/services";
import { reactive } from "vue";
import { mapGetters } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapGetters(["balance"]),
  },
  components: {
    Dropdown,
  },

  data() {
    return {
      isOpen: false,
      dropdownItem: [
        {
          title: "Logout",
          link: "#",
        },
      ],
    };
  },
  mounted() {
    ApiService.getBalance((res) =>
      this.$store.commit("addBalance", res.data[0])
    );
  },
  methods: {
    optionDropdown(isOpen) {
      this.isOpen = !isOpen;
    },
  },
  setup() {
    const authentication = reactive({
      user_name: localStorage.getItem("wax_user"),
    });
    return {
      authentication,
    };
  },
};
</script>
<style scoped>
.logo {
  width: 100px;
  float: left;
}
.logo_img {
  width: 240px;
  display: block;
}

.navbar {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 10px 0 10px rgb(249 243 243 / 50%);
}
.nav-link {
  display: block;
  font-weight: bold;
  font-size: 20px;
}
</style>