import {
    createRouter,
    createWebHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Staked from '../views/Staked'
import Shop from '../views/Shop'
import ApiService from '../services'
import mainView from '../components/Main'
const routes = [{
        path: '/',
        redirect: '/dashboard',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/dashboard',
            name: 'dashboard',
            props: true,
            component: Home,
            meta: {
                sideBarKey: 'Dashboard'
            }
        }]
    },
    {
        path: '/staked',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/staked',
            name: 'staked',
            component: Staked,
            meta: {
                sideBarKey: 'staked'
            }
        }]
    },
    {
        path: '/rewards',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/rewards',
            name: 'reward',
            component: Staked,
            meta: {
                sideBarKey: 'rewards'
            }
        }]
    },
    {
        path: '/shop',
        component: mainView,
        // dashboard auth guard
        beforeEnter(to, from, next) {
            if (!isLogin()) {
                next('/login')
            } else {
                next()
            }
        },
        children: [{
            path: '/shop',
            name: 'shop',
            component: Shop,
            meta: {
                sideBarKey: 'shop'
            }
        }]
    },
    {
        path: '/login',
        name: 'Login',
        // props: false,
        component: Login
    }
]
const isLogin = function () {
    return ApiService.isLogin()
}
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
// router.beforeEach((to, from, next) => {
//     if (window.location.protocol !== 'https:' && window.location.hostname == 'play.ancientoasis.world') {
//         next('https://' + window.location.hostname + to.path)
//     } else {
//         next()
//     }
// })
export default router