import { UALJs } from "ual-plainjs-renderer";
import { Anchor } from "ual-anchor";
import { Wax } from "@eosdacio/ual-wax";

// login functio
var ual = null
export default class LoginService {
    constructor() {
        this.chain = {
            chainId: process.env.VUE_APP_CHAIN_ID,
            rpcEndpoints: [{
                protocol: "https",
                host: process.env.VUE_APP_CHAIN_URL,
                port: '443',
            }, ],
        };
        this.wax = new Wax([this.chain], { appName: process.env.VUE_APP_NAME });
        this.anchor = new Anchor([this.chain], { appName: process.env.VUE_APP_NAME });
        this.appName = process.env.VUE_APP_NAME;
        this.ual = new UALJs(this.loginCallBack, [this.chain], this.appName, [this.wax, this.anchor]);
    }
    setUal(myAppRoot) {
        this.ual = new UALJs(this.loginCallBack, [this.chain], this.appName, [this.wax, this.anchor], myAppRoot);
    }
    loginCallBack(users) {
        if (users[0].session) {
            localStorage.setItem("ual-session-authenticator", "anchor")
        } else {
            localStorage.setItem("ual-session-authenticator", "Wax")
        }
        localStorage.setItem('wax_user', users[0].accountName)
        window.location.href = '/dashboard'
    }
    handle_login() {

        //auth partners
        // login component load to HTML
        const myAppRoot = {
            containerElement: document.getElementById('ual-div')
        }

        // validate authentication
        this.setUal(myAppRoot);
        (async() => {
            await this.ual.init();
        })()
        return this.ual
    }
    getSession() {
        // validate authentication
        const auth = this.ual.getAuthenticators()
        this.ual.attemptSessionLogin(auth.availableAuthenticators);
        return this.ual
    }
    getLoginUser() {
        const activeUser = localStorage.getItem("wax_user");
        if (this.ual) {
            if (localStorage.getItem("ual-session-authenticator") == "Wax") {
                this.ual.loginUser(this.wax)
            } else {
                this.ual.loginUser(this.anchor, activeUser)
            }
            return ual
        }
        return this.ual
    }

}